import React from "react"

// default components
import {
  Layout,
  DestinationsMobile,
  Landing,
  GreenBar,
  BannerHero,
  BoxContainer,
  TourBanner,
  Banner,
  Reviews,
  Trips,
  WhyUsMobile,
  FeaturedMobile,
  useImageQuery,
  useHomePageQuery,
  useCountryQuery,
  useDestinationQuery,
  renderSeo,
  useFeatureBox,
  resolveVariationClass,
  Heading,
} from "@nt-websites/navigate-theme"

const IndexPage = ({ data }) => {
  // extracting our custom hook

  const imageQuery = useImageQuery()
  const homeQuery = useHomePageQuery()
  const countryQuery = useCountryQuery()
  const destinationQuery = useDestinationQuery()
  const featuredBoxData = useFeatureBox()
  // getting the number of tours for each country
  const filterDestinations = destination => {
    const result = destinationQuery.filter(
      dest => dest.node.destinationCountry === destination
    )
    return result.length
  }

  // rendering all the destination boxes
  //DISABLE-EUROPE
  const renderCountries = () => {
    return countryQuery
      .filter(c => c.node.slug !== "europe")
      .sort((a, b) => a.node.contentfulid - b.node.contentfulid)
      .map((country, idx) => {
        return (
          <React.Fragment key={idx}>
            <DestinationsMobile
              type="country"
              key={idx + 4}
              destination={country.node.slug}
              title={country.node.title}
              subtitle={country.node.days}
              departs={country.node.departure}
              details={country.node.description}
              price={country.node.price}
              tours={filterDestinations(country.node.slug)}
              imageData={country.node.banner.localFile.childImageSharp.fluid}
            />
            <TourBanner
              type="country"
              key={idx + 12}
              destination={country.node.slug}
              title={country.node.title}
              subtitle={country.node.days}
              departs={country.node.departure}
              details={country.node.description}
              price={country.node.price}
              tours={filterDestinations(country.node.slug)}
              imageData={country.node.banner.localFile.childImageSharp.fluid}
              SVGMap={country.node.svgMap.localFile.publicURL}
            />
          </React.Fragment>
        )
      })
  }
  return (
    <Layout>
      {renderSeo(data.contentfulSeoPageMeta)}
      <Landing
        imageData={imageQuery.GrandWildHomeBanner.childImageSharp.fluid}
        titleFirst="Luxury tours "
        TitleSecond="for the"
        TitleThird="adventurous"
        buttonFirst="Explore Tours"
        buttonFirstURL="/tours"
        buttonStyles={["white", "green"]}
        variation={false}
      />
      <GreenBar />
      <FeaturedMobile hideText data={featuredBoxData} />
      <WhyUsMobile data={homeQuery[0].node} />

      <div className="row row--patched">
        <Heading
          as="h2"
          className={`${resolveVariationClass("heading-1")} mobile-yes`}
        >
          Destinations
        </Heading>
      </div>
      <BannerHero
        hideFeaturedText
        imageData={
          homeQuery[0].node.featuredInBannerImage.localFile.childImageSharp
            .fluid
        }
        headerFirst="The perfect way to experience "
        headersecond="New Zealand."
        subHeaderFirst="Discover exquisite scenery in one of the world's most beautiful nature destinations. Venture off the beaten track, seek the unseen and travel in style with Grand & Wild. Uncover natural beauty, explore history and relax into this unique way of travel."
        buttonText="how it works"
      />
      <BoxContainer dataArray={homeQuery[0].node.whyWildKiwi} />
      <div className="row row--patched mobile-no">
        <Heading
          as="h2"
          className={`${resolveVariationClass(
            "heading-1"
          )} u-margin-bottom-small`}
        >
          Destinations
        </Heading>
      </div>
      {/* rendering all destinations */}
      {renderCountries()}
      <Banner {...data.contentfulSeoPageMeta.bottomBanner} />
      <Reviews />
      {/*<Trips data={homeQuery[0].node.popularTours} headerText="Popular Tours" />*/}
    </Layout>
  )
}

export default IndexPage
/**
 * We should use seo identifier variables from const PAGE_SEO_IDENTIFIER on this query instead plain strings. . But to do so, we need to pass
 * this data as a context. See LekoArts answer in https://github.com/gatsbyjs/gatsby/issues/10023.
 */
export const query = graphql`
  query {
    contentfulSeoPageMeta(referencedPageIdentifier: { eq: "home" }) {
      ...PageMeta
    }
  }
`
